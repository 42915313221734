<template>
  <div class="stock-check">
    <titlebar :name="name" :is_custom_back="true" @goBackCustom="goBack" />

    <div class="page_box">
      <div class="flex flex-ac ft-26 mg-bt30 flex-jsb mg-lt30 mg-rt30">
        <div>盘点单号：<span class="color-1588F5">{{ info.inventory_num || '' }}</span></div>
        <div>盘点时间：<span class="color-1588F5">{{ info.create_time || '' }}</span></div>
        <div>盘点人：<span class="color-1588F5">{{ info.admin || '' }}</span></div>
      </div>
      <div class="ft-26 mg-bt30 text-left mg-lt30 mg-rt30">备注：<span class="color-1588F5">{{ info.mark || '无' }}</span></div>
      <table
        width="100%"
        id="table"
        style="border-collapse: collapse"
        border="0"
        cellspacing="0"
        cellpadding="0"
      >
        <thead bgcolor="#F2F2F2">
          <tr
            bgcolor="#F2F2F2"
            class="thead-tr"
            style="height: 8rem;"
          >
            <td class="color-333 ft-24 text-center ft-bold">名称</td>
            <td class="color-333 ft-24 text-center ft-bold">分类</td>
            <td class="color-333 ft-24 text-center ft-bold">单位</td>
            <td class="color-333 ft-24 text-center ft-bold">当前库存</td>
            <td class="color-333 ft-24 text-center ft-bold">盘点后库存</td>
            <td class="color-333 ft-24 text-center ft-bold">盈亏数量</td>
          </tr>
        </thead>

        <tbody v-if="good_list.length > 0" class="relative">
          <tr v-for="(item, index) in good_list" class="color-333 ft-24 text-center tbody-tr relative">
            <td>{{ item.name }}</td>
            <td>{{ item.cate }}</td>
            <td>{{ item.dw }}</td>
            <td>{{ item.front }}</td>
            <td>{{ item.after }}</td>
            <td>{{ item.diff_num }}</td>
          </tr>
        </tbody>
      </table>
      <div style="height: 13rem;"></div>
    </div>
  </div>
</template>
<script>
import titlebar from "@/views/component/titlebar";
export default {
  name: 'stock-check',
  components: {
    titlebar,
  },
  data() {
    return {
      name: 'stockCheckDetail',
      id: '',
      info: {},
      good_list: [],
    }
  },
  created() {
    this.id = this.$route.query.log_id || '';
    this.getData();
  },
  methods: {
    getData() {
      this.$api.inventoryLog_getListInfo({
        inv_id: this.id,
      }).then(res => {
        if (res.code == 1) {
          this.info = res.data.log || {};
          this.good_list = res.data.info || [];
          this.good_list.forEach(element => {
            let diff = this.$bigN(element.after).minus(element.front).toNumber();
            element.diff_num = diff > 0 ? `+${diff}` : diff;
          });
        } else {
          this.$toast(res.msg);
        }
      })
    },
    goBack() {
      this.$router.replace({
        name: 'featureSet',
        query: {
          name: 'featureSet',
          active: this.$route.query.active,
        },
        params: {
          tab_id: this.$route.query.tab_id,
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
  .stock-check {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .page_box {
    position: relative;
    height: calc(100% - 17rem);
    background: #fff;
    padding: 4rem 3rem 3rem;
    margin-top: 1.5rem;
    overflow-y: scroll;
    overflow-x: hidden;

    .check-remark-input {
      width: 50%;
      height: 6.2rem;
      border: 0.2rem solid #DEDEDE;
      border-radius: 0.8rem;
      margin-left: 2.4rem;
      padding: 0 2rem;
      font-size: 2.4rem;
    }

    table {
      background: white;
      display: table;
      width: 100%;

      thead {
        width: 100%;
        display: block;
        display: table;
        table-layout: fixed;
      }

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        vertical-align: middle;
      }
      
      tbody {
        display: block;
        width: 100%;
      }
      .tbody-tr {
        overflow-y: scroll;
        height: 8rem;
        width: 100%;
        border-bottom: 0.2rem rgba(0, 0, 0, 0.06) solid;
        &.odd {
          background: #fef7ef;
        }
        td {
          height: 8rem;
        }

        .stock-input {
          width: 40%;
          height: 6rem;
          border: 0.2rem solid #DEDEDE;
          border-radius: 0.8rem;
          padding: 0 2rem;
          font-size: 2.4rem;
        }
      }
    }
  }
</style>